/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React from 'react';
import {useTable, usePagination, useSortBy, useRowSelect} from 'react-table';
import * as S from './Table.styles';
import Pagination from './Pagination/Pagination';
import {ReactComponent as AscendingIcon} from 'assets/icons/ascending.svg';
import {ReactComponent as DescendingIcon} from 'assets/icons/descending.svg';
import {Select} from 'components/core/form/Select';

const Table = ({
  columns,
  data,
  sortBy,
  fetchData,
  showTitle,
  pageCount: controlledPageCount,
  manual,
  hasPagination,
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      manual,
      manualPagination: true,
      pageCount: controlledPageCount,
      data,
      initialState: {pageIndex: 0, sortBy},
    },
    useSortBy,
    usePagination,
    useRowSelect,
  );
  React.useEffect(() => {
    fetchData({pageIndex, pageSize});
  }, [fetchData, pageIndex, pageSize]);
  const renderOrderIcon = (column) => {
    if (column.isSorted && column.isSortedDesc) {
      return <DescendingIcon style={{marginLeft: '5px'}} />;
    }
    if (column.isSorted && !column.isSortedDesc) {
      return <AscendingIcon style={{marginLeft: '5px'}} />;
    }
    return '';
  };
  return (
    <>
      <S.TableContainer>
        <S.Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <S.Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <S.Th
                    {...column.getHeaderProps([
                      {style: {minWidth: column.minWidth}},
                      column.getSortByToggleProps(),
                    ])}>
                    {column.render('Header')}
                    {renderOrderIcon(column)}
                  </S.Th>
                ))}
              </S.Tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <S.Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <S.Td
                        {...cell.getCellProps([
                          {style: {width: cell.column.width}},
                        ])}>
                        {cell.render('Cell')}
                      </S.Td>
                    );
                  })}
                </S.Tr>
              );
            })}
          </tbody>
        </S.Table>
      </S.TableContainer>
      {hasPagination && (
        <S.Pagination>
          <Pagination
            type="numbered"
            totalPages={pageCount}
            currentPage={pageIndex}
            onPageChange={(newPage) => {
              gotoPage(newPage - 1);
            }}
          />
          <div>
            <S.Text>Visualizar:</S.Text>
            <Select
              value={pageSize}
              onChange={(e) => {
                gotoPage(0);
                setPageSize(Number(e.target.value));
              }}>
              {[10, 20, 30, 40, 50].map((pageSizes) => (
                <option key={pageSizes} value={pageSizes}>
                  {pageSizes} {showTitle}
                </option>
              ))}
            </Select>
          </div>
        </S.Pagination>
      )}
    </>
  );
};
Table.defaultProps = {
  columns: [],
  data: [],
  showTitle: 'usuários',
  sortBy: [],
  pageCount: 1,
  fetchData: () => {},
  hasPagination: false,
};
export default Table;
