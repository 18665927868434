import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Dropdown = styled.div`
  float: left;
  overflow: hidden;
  ${tokens.media.xs} {
    float: none;
  }
`;

export const DropButton = styled.button`
  cursor: pointer;
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${tokens.colors.whiteLight};
  min-width: 160px;
  ${tokens.media.xs} {
    width: 100%;
  }
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  ${({open}) => open && `display: block;`}
`;

export const DropdownItem = styled.div`
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: ${tokens.colors.pink};
  }
`;

export const IconContent = styled.div`
  margin-left: 5px;
  display: unset;
`;
