import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {RESOURCE_BANNERS, basePhoto, DELETE_BANNER} from 'constants/endpoints';
import {GET, POST, PUT, DELETE} from 'constants/verbs';
import {BannerActions, BannerTypes} from './banner.ducks';
import {history} from 'constants/routes';
import urls from 'constants/urls';

export function* getBanners({type}) {
  try {
    const response = yield api({
      method: GET,
      url: RESOURCE_BANNERS,
    });

    yield put(BannerActions.getBannersSuccess(response.data));
  } catch (error) {
    yield put(BannerActions.bannerRequestFailure(error, type));
  }
}

export function* deleteBanner({payload, type}) {
  try {
    yield api({
      method: DELETE,
      url: DELETE_BANNER(payload),
    });

    yield put(BannerActions.bannerRequestSuccess(type));
    yield put(BannerActions.getBanners());
  } catch (error) {
    yield put(BannerActions.bannerRequestFailure(error, type));
  }
}
export function* getBanner({payload, type}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_BANNERS}/${payload}`,
    });

    yield put(
      BannerActions.getBannerSuccess({
        ...response.data.banner,
        cover: `${basePhoto}${response.data.banner.cover}`,
      }),
    );
  } catch (error) {
    yield put(BannerActions.bannerRequestFailure(error, type));
  }
}
export function* updateBanner({id, payload, type}) {
  try {
    yield api({
      method: PUT,
      url: `${RESOURCE_BANNERS}/${id}`,
      data: payload,
    });

    yield put(BannerActions.updateBannerSuccess());
    yield history.push(urls.LINKS.BANNERS);
  } catch (error) {
    yield put(BannerActions.bannerRequestFailure(error, type));
  }
}
export function* createBanner({payload, type}) {
  try {
    yield api({
      method: POST,
      url: RESOURCE_BANNERS,
      data: payload,
    });

    yield put(BannerActions.createBannerSuccess());
    yield history.push(urls.LINKS.BANNERS);
  } catch (error) {
    yield put(BannerActions.bannerRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(BannerTypes.GET_BANNERS, getBanners);
  yield takeLatest(BannerTypes.GET_BANNER, getBanner);
  yield takeLatest(BannerTypes.CREATE_BANNER, createBanner);
  yield takeLatest(BannerTypes.UPDATE_BANNER, updateBanner);
  yield takeLatest(BannerTypes.DELETE_BANNER, deleteBanner);
}
