import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const RadioContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide radio visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenRadio = styled.input.attrs({type: 'radio'})`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const RadioInput = styled.input.attrs({type: 'radio'})`

-webkit-appearance: none;
appearance: none;
background-color: ${tokens.colors.white};
margin: 0;
font: inherit;
color: ${tokens.colors.pink};
width: 1.15em;
height: 1.15em;
border: 0.15em solid ${tokens.colors.gray01};
border-radius: 50%;
transform: translateY(-0.075em);
display: grid;
place-content: center;
&::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 0.1em 0.1em ${tokens.colors.pink};
  background-color: ${tokens.colors.pink};
}
&:checked::before {
  transform: scale(1);
}
&:focus {
 
}
`
export const StyledRadio = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.checked ? tokens.colors.pink : 'transparent'};
  border-radius: 8px;
  border: ${(props) => (props.checked ? 'none' : '1px solid #d6d8dd')};
  box-sizing: border-box;
  transition: all 150ms;
  cursor: pointer;
  ${HiddenRadio}:focus + & {
    box-shadow: 0 0 0 3px ${tokens.colors.pink};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
export const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  margin-bottom: ${tokens.spacing.sm};

  color: ${tokens.colors.black01};
  span {
    margin-left: ${tokens.spacing.sm};
  }
`;
