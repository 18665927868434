import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {RESOURCE_MANAGERS, DELETE_MANAGER} from 'constants/endpoints';
import {GET, POST, DELETE} from 'constants/verbs';
import {ManagerActions, ManagerTypes} from './manager.ducks';
import {serialize} from 'lib/core/utility';
import {MessagesActions} from '../message/message.ducks';

export function* listManager({params, type}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_MANAGERS}?${serialize(params)}`,
    });

    yield put(ManagerActions.listManagerSuccess(response.data));
  } catch (error) {
    yield put(ManagerActions.managerRequestFailure(error, type));
  }
}
export function* createManager({payload, callback, type}) {
  try {
    yield api({
      method: POST,
      url: RESOURCE_MANAGERS,
      data: payload,
    });
    yield put(
      MessagesActions.open({
        contentText: 'Gerente adicionado com sucesso!',
        type: 'success',
      }),
    );
    yield put(ManagerActions.managerRequestSuccess(type));
    callback();
  } catch (error) {
    yield put(MessagesActions.close());
    yield put(
      MessagesActions.open({
        contentText: error.response.data
          ? error.response.data.errors[0]
          : 'Erro ao salvar',
        type: 'error',
      }),
    );
    yield put(ManagerActions.managerRequestFailure(error, type));
  }
}

export function* deleteManager({payload, callback, type}) {
  try {
    yield api({
      method: DELETE,
      url: DELETE_MANAGER(payload),
    });

    yield put(ManagerActions.managerRequestSuccess(type));
    yield put(
      MessagesActions.open({
        contentText: 'Gerente deletado com sucesso!',
        type: 'success',
      }),
    );
    callback();
  } catch (error) {
    yield put(
      MessagesActions.open({
        contentText: error.response.data
          ? error.response.data.errors[0]
          : 'Erro ao deletar',
        type: 'error',
      }),
    );
    yield put(ManagerActions.managerRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(ManagerTypes.LIST_MANAGER, listManager);
  yield takeLatest(ManagerTypes.CREATE_MANAGER, createManager);
  yield takeLatest(ManagerTypes.DELETE_MANAGER, deleteManager);
}
