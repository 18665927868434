import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from "constants/request";

export const { Types: PostTypes, Creators: PostActions } = createActions({
  getPosts: ["payload"],
  getPostsSuccess: ["payload"],
  deletePost: ["payload"],
  postRequestSuccess: ["originalType"],
  postRequestFailure: ["error", "originalType"],
  getPost: ["payload"],
  getPostSuccess: ["payload"],
  createPost: ["payload"],
  createPostSuccess: ["payload"],
  updatePost: ["id", "payload"],
  updatePostSuccess: ["payload"],
});

const INITIAL_STATE = {
  data: [],
  resource: null,
  error: null,
  postRequests: {
    [PostTypes.GET_POSTS]: REQUEST_NOT_STARTED,
    [PostTypes.GET_POST]: REQUEST_NOT_STARTED,
    [PostTypes.UPDATE_POST]: REQUEST_NOT_STARTED,
    [PostTypes.CREATE_POST]: REQUEST_NOT_STARTED,
    [PostTypes.DELETE_POST]: REQUEST_NOT_STARTED,
  },
};

const getPosts = (state) => ({
  ...state,
  postRequests: {
    ...INITIAL_STATE.postRequests,
    [PostTypes.GET_POSTS]: REQUEST_PENDING,
  },
});

const getPostsSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  postRequests: {
    ...INITIAL_STATE.postRequests,
    [PostTypes.GET_POSTS]: REQUEST_RESOLVED,
  },
});

const deletePost = (state) => ({
  ...state,
  postRequests: {
    ...INITIAL_STATE.postRequests,
    [PostTypes.DELETE_POST]: REQUEST_PENDING,
  },
});

const postRequestSuccess = (state, { originalType }) => ({
  ...state,
  postRequests: {
    ...state.postRequests,
    [originalType]: REQUEST_RESOLVED,
  },
});

const postRequestFailure = (state, { error: { response }, originalType }) => ({
  ...state,
  error: response,
  postRequests: {
    ...state.postRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const createPost = (state) => ({
  ...state,
  postRequests: {
    ...INITIAL_STATE.postRequests,
    [PostTypes.CREATE_POST]: REQUEST_PENDING,
  },
});

const createPostSuccess = (state) => ({
  ...state,
  postRequests: {
    ...INITIAL_STATE.postRequests,
    [PostTypes.CREATE_POST]: REQUEST_RESOLVED,
  },
});
const updatePost = (state) => ({
  ...state,
  postRequests: {
    ...INITIAL_STATE.postRequests,
    [PostTypes.UPDATE_POST]: REQUEST_PENDING,
  },
});

const updatePostSuccess = (state) => ({
  ...state,
  postRequests: {
    ...INITIAL_STATE.postRequests,
    [PostTypes.UPDATE_POST]: REQUEST_RESOLVED,
  },
});
const getPost = (state) => ({
  ...state,
  resource: null,
  postRequests: {
    ...INITIAL_STATE.postRequests,
    [PostTypes.GET_POST]: REQUEST_PENDING,
  },
});

const getPostSuccess = (state, { payload }) => ({
  ...state,
  resource: payload,
  postRequests: {
    ...INITIAL_STATE.postRequests,
    [PostTypes.GET_POST]: REQUEST_RESOLVED,
  },
});

export default createReducer(INITIAL_STATE, {
  [PostTypes.GET_POSTS]: getPosts,
  [PostTypes.GET_POSTS_SUCCESS]: getPostsSuccess,
  [PostTypes.GET_POST]: getPost,
  [PostTypes.GET_POST_SUCCESS]: getPostSuccess,
  [PostTypes.CREATE_POST]: createPost,
  [PostTypes.CREATE_POST_SUCCESS]: createPostSuccess,
  [PostTypes.UPDATE_POST]: updatePost,
  [PostTypes.UPDATE_POST_SUCCESS]: updatePostSuccess,
  [PostTypes.DELETE_POST]: deletePost,
  [PostTypes.POST_REQUEST_SUCCESS]: postRequestSuccess,
  [PostTypes.POST_REQUEST_FAILURE]: postRequestFailure,
});
