import {createGlobalStyle} from 'styled-components';
import tokens from 'assets/styles/tokens';
import GilroyBold from 'assets/styles/fonts/Gilroy-Bold.otf';
import GilroyMedium from 'assets/styles/fonts/Gilroy-Medium.otf';
import GilroyRegular from 'assets/styles/fonts/Gilroy-Regular.otf';
import GilroySemiBold from 'assets/styles/fonts/Gilroy-SemiBold.otf';

const GlobalStyle = createGlobalStyle` 
@font-face {
  font-family: 'GilroyRegular';
  src: url(${GilroyRegular}) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'GilroyMedium';
  src: url(${GilroyMedium}) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'GilroySemiBold';
  src: url(${GilroySemiBold}) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'GilroyBold';
  src: url(${GilroyBold}) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
  * {    
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  body {
    margin:0;
    font-family: 'GilroyRegular', sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: ${tokens.colors.background};
  }
`;
export default GlobalStyle;
