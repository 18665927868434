/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import * as S from './DateInput.styles';
import DatePicker, {registerLocale} from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ptBr', ptBr);

const DateInput = (props) => {
  const {
    label,
    inputRef,
    errors,
    onChange,
    value,
    filterDate,
    disabled,
    minDate,
    maxDate,
    dateFormat,
    showMonthYearPicker,
    showTimeSelect,
  } = props;
  return (
    <S.InputContainer>
      <S.Label>{label} </S.Label>
      <DatePicker
        ref={inputRef}
        onChange={onChange}
        selected={value}
        value={value}
        minDate={new Date(minDate)}
        maxDate={new Date(maxDate)}
        dateFormat={dateFormat}
        filterDate={filterDate}
        showTimeSelect={showTimeSelect}
        showMonthYearPicker={showMonthYearPicker}
        {...props}
        locale="ptBr"
        disabled={disabled}
      />
      {errors && (
        <S.AlignEnd>
          <S.Required>{errors}</S.Required>
        </S.AlignEnd>
      )}
    </S.InputContainer>
  );
};
DateInput.defaultProps = {
  disabled: false,
  dateFormat: 'dd/MM/yyyy',
  showMonthYearPicker: false,
  showTimeSelect: false,
};
export default DateInput;
