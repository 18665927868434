import React from 'react';
import Modal from 'components/core/Modal';
import Button from 'components/core/Button';
import * as S from './ModalConfirm.styles';

export const ModalConfirm = ({
  collapse,
  callBack,
  onCancel,
  messageTitle,
  headerTitle,
  submitTitle,
  form,
}) => {
  const submit = () => {
    callBack();
  };

  return (
    <Modal collapse={collapse}>
      <S.Content>
        <S.Header>{headerTitle}</S.Header>

        <S.Title> {messageTitle}</S.Title>
        <S.ButtonContainer>
          <Button
            type="submit"
            color="secondary"
            padding="14px 14px"
            onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            form={form}
            padding="14px 14px"
            onClick={submit}>
            {submitTitle}
          </Button>
        </S.ButtonContainer>
      </S.Content>
    </Modal>
  );
};

ModalConfirm.defaultProps = {
  collapse: false,
  itemName: '',
  callBack: () => {},
  onCancel: () => {},
};
