const urls = {
  ROUTES: {
    APP: '/',
    LOGIN: '/login',
    DASHBOARD: '/',
    USERS: '/users/:id',
    POSTS: '/posts',
    CREATE_POST: '/posts/new',
    EDIT_POST: '/posts/:id/edit',
    BANNERS: '/banners',
    CREATE_BANNER: '/banners/new',
    EDIT_BANNER: '/banners/:id/edit',
    RADIOS: '/radios',
    CREATE_RADIO: '/radios/new',
    EDIT_RADIO: '/radios/:id/edit',
    EVENTS: '/events',
    CREATE_EVENT: '/events/new',
    SHOW_EVENT: '/events/:id',
    EDIT_EVENT: '/events/:id/edit',
    CHAT: '/chat',
    TRAINING_DETAILS: '/trainings/:id',
  },
  LINKS: {
    APP: '/',
    LOGIN: '/login',
    DASHBOARD: '/',
    POSTS: '/posts',
    CREATE_POST: '/posts/new',
    EDIT_POST: (id) => `/posts/${id}/edit`,
    BANNERS: '/banners',
    CREATE_BANNER: '/banners/new',
    EDIT_BANNER: (id) => `/banners/${id}/edit`,
    RADIOS: '/radios',
    CREATE_RADIO: '/radios/new',
    EDIT_RADIO: (id) => `/radios/${id}/edit`,
    EVENTS: '/events',
    CREATE_EVENT: '/events/new',
    EDIT_EVENT: (id) => `/events/${id}/edit`,
    SHOW_EVENT: (id) => `/events/${id}`,
    CHAT: '/chat',
    USERS: (id) => `/users/${id}`,
    TRAINING_DETAILS: (id) => `/trainings/${id}`,
  },
};

export default urls;
