import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Content = styled.div`
  padding: ${tokens.spacing.lg};
`;
export const Header = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${tokens.colors.black01};
  padding-bottom: ${tokens.spacing.lg};
`;

export const Box = styled.div`
  background: ${tokens.colors.white};
  border: 1px solid #d6d9dc;
  box-sizing: border-box;
  border-radius: 8px;
  padding: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;

export const Title = styled.h2`
  font-size: 16px;
  line-height: 22px;
  color: ${tokens.colors.black01};
  font-weight: 400;
`;
export const Form = styled.form`
  transition: all 0.4s;
`;

export const FormGroup = styled.div`
  margin-top: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;

export const ButtonContainer = styled.div`
  display: grid;
  padding-top: 16px;
  grid-template-columns: 49% 49%;
  grid-gap: 10px;
`;
