import React from 'react';
import UploadFile from 'components/core/form/UploadFile';
import * as S from './Form.styles';
import Input from 'components/core/form/Input';
import Button from 'components/core/Button';
import HandleMessage from 'components/core/HandleMessage';
import {isPending, isReject} from 'lib/core/request';
import Loading from 'components/core/Loading';
import DateInput from 'components/core/form/DateInput';
import Radio from 'components/core/form/Radio';
import SelectInput from 'components/core/form/SelectInput';
import {Row} from 'components/structure/Row';
import {Col} from 'components/structure/Col';
import moment from 'moment';
import 'moment/locale/pt-br';
import Card from 'components/core/Card';
// import {CropImage} from 'components/core/form/CropImage';
import TextArea from 'components/core/form/TextArea';
import {InputTag} from 'components/core/form/InputTag';

const Form = ({
  register,
  Controller,
  control,
  watch,
  handleSubmit,
  error,
  errors,
  request,
  onSubmit,
  eventsOptions,
  // setCroppedImage,
  tags,
  setTags,
}) => {
  const watchFields = watch(['scheduleOptions', 'kind', 'content_kind']); // you can also target specific fields by their names

  return (
    <S.FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <S.FormContent>
          {isReject(request) ? (
            <S.FormGroup>
              <HandleMessage type="error">{error.data.errors[0]}</HandleMessage>
            </S.FormGroup>
          ) : null}
          <Row>
            <Col desktop="12" mobile="12">
              <S.FormGroupInput>
                <SelectInput
                  inputRef={register}
                  label="Evento"
                  name="event_id"
                  options={eventsOptions}
                  placeholder="Informe o evento"
                  errors={errors.event_id && errors.event_id.message}
                />
              </S.FormGroupInput>
            </Col>
          </Row>

          <Row>
            <Col desktop="6" mobile="12">
              <S.FormGroupInput>
                <S.FormRadioGroup>
                  <S.FormRadioGroupLabel>
                    Data da publicação
                  </S.FormRadioGroupLabel>
                  <Radio
                    inputRef={register}
                    label="Publicar Imediatamente"
                    type="radio"
                    name="scheduleOptions"
                    value="publish"
                    errors={
                      errors.scheduleOptions && errors.scheduleOptions.message
                    }
                  />

                  <Radio
                    inputRef={register}
                    label="Agendar"
                    type="radio"
                    value="schedule"
                    name="scheduleOptions"
                    errors={
                      errors.scheduleOptions && errors.scheduleOptions.message
                    }
                  />
                  <input inputRef={register} type="hidden" name="expired_at" />
                </S.FormRadioGroup>
              </S.FormGroupInput>
            </Col>
            {watchFields['scheduleOptions'] === 'schedule' && (
              <Col desktop="6" mobile="12">
                <S.FormGroupInput>
                  <Controller
                    control={control}
                    name="published_at"
                    render={({onChange, value}) => {
                      return (
                        <DateInput
                          inputRef={register}
                          label="Horário agendado"
                          value={value}
                          onChange={onChange}
                          type="text"
                          name="published_at"
                          showTimeSelect
                          placeholderText="Data de publicação"
                          dateFormat="dd/MM/yyyy, HH:mm"
                          minDate={moment()}
                          timeFormat="HH:mm"
                          timeCaption="Horário"
                          locale="pt-br"
                          placeholder="Informe o horario agendado"
                          errors={
                            errors.published_at && errors.published_at.message
                          }
                        />
                      );
                    }}
                  />
                </S.FormGroupInput>
              </Col>
            )}
          </Row>
          <Row>
            <Col desktop="12" mobile="12">
              <S.FormGroupInput>
                <TextArea
                  label="Legenda / Descrição"
                  inputRef={register}
                  name="description"
                />
              </S.FormGroupInput>
            </Col>
          </Row>
          <Row>
            <Col desktop="6" mobile="12">
              <S.FormGroupInput>
                <Input
                  inputRef={register}
                  label="Expirar post após quantas horas?"
                  type="number"
                  name="expired_after"
                  min="1"
                  placeholder="Período limite para divulgador compartilhar a publicação"
                  errors={errors.expired_after && errors.expired_after.message}
                />
              </S.FormGroupInput>
            </Col>
            <Col desktop="6" mobile="12">
              <S.FormGroupInput>
                <SelectInput
                  inputRef={register}
                  label="Formato do Post"
                  name="kind"
                  options={[
                    {label: 'Stories', value: 'stories'},
                    {label: 'Feed', value: 'feed'},
                    {label: 'Postagem Personalizada', value: 'custom'},
                    {label: 'Interação', value: 'interaction'},
                  ]}
                  placeholder="Informe o formato do post"
                  errors={errors.kind && errors.kind.message}
                />
              </S.FormGroupInput>
            </Col>
          </Row>
          <Row>
            {(watchFields['kind'] === 'feed' ||
              watchFields['kind'] === 'stories') && (
              <Col desktop="5" mobile="12">
                <S.FormGroupInput>
                  <SelectInput
                    inputRef={register}
                    label="Tipo de conteúdo"
                    name="content_kind"
                    options={[
                      {label: 'Imagem', value: 'image'},
                      {label: 'Video', value: 'video'},
                    ]}
                    placeholder="Informe o conteúdo"
                    errors={errors.content_kind && errors.content_kind.message}
                  />
                </S.FormGroupInput>
              </Col>
            )}
            {watchFields['kind'] === 'interaction' && (
              <Col desktop="7" mobile="12">
                <S.FormGroupInput>
                  <Input
                    inputRef={register}
                    label="Hiperlink"
                    type="text"
                    name="hiperlink"
                    placeholder="Adicione um link para redirecionar o divulgador a uma página externa"
                    errors={errors.hiperlink && errors.hiperlink.message}
                  />
                </S.FormGroupInput>
              </Col>
            )}
          </Row>

          <Row>
            <Col desktop="12" mobile="12">
              <S.FormGroupInput>
                <InputTag
                  setTags={setTags}
                  tags={tags}
                  label={'Adicionar Menções e Links'}
                />
              </S.FormGroupInput>
            </Col>
          </Row>
          {(watchFields['kind'] === 'feed' ||
            watchFields['kind'] === 'stories') &&
            watchFields['content_kind'] === 'image' && (
              <Row>
                <Col desktop="12">
                  <S.FormGroupInput>
                    <S.FormRadioGroupLabel>Imagem</S.FormRadioGroupLabel>
                    <UploadFile
                      width="100%"
                      name="image"
                      inputRef={register}
                      placeholder="Envie a imagem"
                      height="100px"
                    />
                    {/* <CropImage
                      name="image"
                      kind={watchFields['kind']}
                      inputRef={register}
                      setCroppedImage={setCroppedImage}
                    /> */}
                  </S.FormGroupInput>
                </Col>
              </Row>
            )}
          {(watchFields['kind'] === 'feed' ||
            watchFields['kind'] === 'stories') &&
            watchFields['content_kind'] === 'video' && (
              <Row>
                <Col desktop="12">
                  <S.FormGroupInput>
                    <S.FormRadioGroupLabel>Arquivo</S.FormRadioGroupLabel>
                    <UploadFile
                      width="100%"
                      name="video"
                      inputRef={register}
                      accept="video/*"
                      placeholder="Envie o video"
                      height="100px"
                    />
                  </S.FormGroupInput>
                </Col>
              </Row>
            )}
          <Row>
            <Col desktop="12" mobile="12">
              <S.FormGroupButton>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isPending(request)}>
                  {isPending(request) ? <Loading /> : 'Publicar'}
                </Button>
              </S.FormGroupButton>
            </Col>
          </Row>
        </S.FormContent>
      </Card>
    </S.FormContainer>
  );
};

export default Form;
