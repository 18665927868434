import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as S from './UploadFile.styles';

const UploadFile = ({
  placeholder,
  onChange,
  value,
  disabled,
  name,
  accept,
  required,
  width,
  height,
  errors,
  inputRef,
  preview,
}) => {
  const [fileTitle, setFileTitle] = useState('');
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (value) {
      setFileTitle(value);
    } else {
      setFileTitle('');
    }
  }, [value]);

  console.log('filename', fileName);

  const handleOnchange = (e) => {
    if (e.target.files[0]) {
      onChange(e);
      setFileName(e.target.files[0].name);
      setFileTitle(URL.createObjectURL(e.target.files[0]));
    }
  };
  return (
    <S.Field width={width} height={height}>
      <input
        ref={inputRef}
        id={`play-${name.replace(' ', '-').toLowerCase()}`}
        type="file"
        accept={accept}
        name={name}
        onChange={handleOnchange}
      />

      <label htmlFor={`play-${name.replace(' ', '-').toLowerCase()}`}>
        {fileTitle !== '' && (
          <S.ContainerPreview>
            {preview && (
              <S.ImagePreview
                vWidth={width}
                vHeight={height}
                src={fileTitle}
                alt="file"
              />
            )}
            <span>{fileName}</span>
            <div>Alterar</div>
          </S.ContainerPreview>
        )}
        <span>{fileTitle === '' && <span>{placeholder}</span>}</span>
      </label>
      {errors && (
        <S.AlignEnd>
          <S.Required>{errors}</S.Required>
        </S.AlignEnd>
      )}
    </S.Field>
  );
};
UploadFile.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  width: PropTypes.string,
  preview: PropTypes.bool,
};

UploadFile.defaultProps = {
  name: '',
  value: '',
  placeholder: 'Selecione o arquivo',
  required: false,
  accept: '',
  width: '200px',
  height: '100px',
  disabled: false,
  onChange: () => {},
  preview: false,
};

export default UploadFile;
