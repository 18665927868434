import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {RESOURCE_CODE_EVENTS} from 'constants/endpoints';
import {GET, POST} from 'constants/verbs';
import {CodeEventActions, CodeEventTypes} from './codeEvent.ducks';
import {serialize} from 'lib/core/utility';
import {MessagesActions} from '../message/message.ducks';

export function* getLastCodeEvent({params, type}) {
  console.log('PARAMS', params);
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_CODE_EVENTS}/last_code_event?${serialize(params)}`,
    });

    yield put(CodeEventActions.getLastCodeEventSuccess(response.data));
  } catch (error) {
    yield put(CodeEventActions.codeEventRequestFailure(error, type));
  }
}
export function* createCodeEvent({payload, type}) {
  try {
    yield api({
      method: POST,
      url: RESOURCE_CODE_EVENTS,
      data: payload,
    });

    yield put(CodeEventActions.createCodeEventSuccess());
    yield put(
      MessagesActions.open({
        contentText: 'Código adicionado com sucesso!',
        type: 'success',
      }),
    );
  } catch (error) {
    yield put(
      MessagesActions.open({
        contentText: error.response.data
          ? error.response.data.errors[0]
          : 'Erro ao salvar',
        type: 'error',
      }),
    );
    yield put(CodeEventActions.codeEventRequestFailure(error, type));
  }
}
export function* watchSagas() {
  yield takeLatest(CodeEventTypes.GET_LAST_CODE_EVENT, getLastCodeEvent);
  yield takeLatest(CodeEventTypes.CREATE_CODE_EVENT, createCodeEvent);
}
