import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: CodeEventTypes,
  Creators: CodeEventActions,
} = createActions({
  createCodeEvent: ['payload'],
  createCodeEventSuccess: ['payload'],
  getLastCodeEvent: ['params'],
  getLastCodeEventSuccess: ['payload'],
  codeEventRequestSuccess: ['originalType'],
  codeEventRequestFailure: ['error', 'originalType'],
});

const INITIAL_STATE = {
  data: [],
  resource: null,
  error: null,
  codeEventRequests: {
    [CodeEventTypes.GET_LAST_CODE_EVENT]: REQUEST_NOT_STARTED,
    [CodeEventTypes.CREATE_CODE_EVENT]: REQUEST_NOT_STARTED,
  },
};

const getLastCodeEvent = (state) => ({
  ...state,
  codeEventRequests: {
    ...INITIAL_STATE.codeEventRequests,
    [CodeEventTypes.GET_LAST_CODE_EVENT]: REQUEST_PENDING,
  },
});

const getLastCodeEventSuccess = (state, {payload}) => ({
  ...state,
  resource: payload,
  codeEventRequests: {
    ...INITIAL_STATE.codeEventRequests,
    [CodeEventTypes.GET_LAST_CODE_EVENT]: REQUEST_RESOLVED,
  },
});

const createCodeEvent = (state) => ({
  ...state,
  codeEventRequests: {
    ...INITIAL_STATE.codeEventRequests,
    [CodeEventTypes.CREATE_CODE_EVENT]: REQUEST_PENDING,
  },
});

const createCodeEventSuccess = (state) => ({
  ...state,
  codeEventRequests: {
    ...INITIAL_STATE.codeEventRequests,
    [CodeEventTypes.CREATE_CODE_EVENT]: REQUEST_RESOLVED,
  },
});

const codeEventRequestSuccess = (state, {originalType}) => ({
  ...state,
  codeEventRequests: {
    ...state.codeEventRequests,
    [originalType]: REQUEST_RESOLVED,
  },
});

const codeEventRequestFailure = (state, {error: {response}, originalType}) => ({
  ...state,
  error: response,
  codeEventRequests: {
    ...state.codeEventRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [CodeEventTypes.GET_LAST_CODE_EVENT]: getLastCodeEvent,
  [CodeEventTypes.GET_LAST_CODE_EVENT_SUCCESS]: getLastCodeEventSuccess,
  [CodeEventTypes.CREATE_CODE_EVENT]: createCodeEvent,
  [CodeEventTypes.CREATE_CODE_EVENT_SUCCESS]: createCodeEventSuccess,
  [CodeEventTypes.CODE_EVENT_REQUEST_SUCCESS]: codeEventRequestSuccess,
  [CodeEventTypes.CODE_EVENT_REQUEST_FAILURE]: codeEventRequestFailure,
});
