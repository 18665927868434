/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo} from 'react';
import * as S from '../LoginForm.styles';

import Input from 'components/core/form/Input';
import Button from 'components/core/Button';
import Link from 'components/core/Link';
import {useDispatch, useSelector} from 'react-redux';
import HandleMessage from 'components/core/HandleMessage';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import {AuthActions} from 'store/auth/auth.ducks';
import {isPending, isReject} from 'lib/core/request';
import Loading from 'components/core/Loading';

const Login = ({setForm}) => {
  const dispatch = useDispatch();
  const {authRequests, error} = useSelector(({auth}) => auth);

  const validationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .required('Campo de e-mail obrigatório')
          .email('Digite um e-mail válido'),
        password: yup.string().required('Campo de e-mail obrigatório'),
      }),
    [],
  );
  const resolver = useYupValidationResolver(validationSchema);

  const {register, handleSubmit, errors, clearErrors} = useForm({resolver});

  const signIn = (data) => {
    const newData = {
      auth: data,
    };
    dispatch(AuthActions.signIn(newData));
  };

  return (
    <S.FormLogin onSubmit={handleSubmit(signIn)}>
      <S.Title>
        <b>Informe seus dados para entrar</b>
      </S.Title>
      <Input
        label="Seu e-mail"
        placeholder="Informe seu email"
        defaultValue=""
        name="email"
        inputRef={register}
        errors={errors.email && errors.email.message}
      />

      <Input
        inputRef={register}
        label="Sua senha"
        type="password"
        name="password"
        placeholder="Informe sua senha"
        errors={errors.password && errors.password.message}
      />
      <S.FormGroup>
        <Link
          onClick={() => {
            setForm('forgot');
            clearErrors();
          }}>
          Esqueci minha senha
        </Link>
      </S.FormGroup>
      <Button
        type="submit"
        color="primary"
        disabled={isPending(authRequests.SIGN_IN)}>
        {isPending(authRequests.SIGN_IN) ? <Loading /> : 'Entrar'}
      </Button>
      {isReject(authRequests.SIGN_IN) ? (
        <S.FormGroup>
          <HandleMessage type="error">{error.data.errors[0]}</HandleMessage>
        </S.FormGroup>
      ) : null}
    </S.FormLogin>
  );
};

export default Login;
