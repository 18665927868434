/* eslint-disable no-dupe-keys */
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 70px;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;
export const ItemSmall = styled.div`
  grid-template-rows: 50px 100px 50px 150px;
`;
export const ItemLarge = styled.div`
  grid-template-columns: 100px auto 50px;
  grid-template-rows: 50px 200px auto;
  padding: 16px;
`;
