import ENV from 'constants/env';

const base = ENV.URL;
// const base = 'http://sarun-hml.us-east-1.elasticbeanstalk.com';
// const service = "api";
// const version = "v1";
// const prefix = "";

export const basePhoto = 'https://files.divulgai.app/';

export const basePath = `${base}/api/v1/`;
export const baseMock = 'https://run.mocky.io/v3/';
export const SIGN_IN = `producer_token`;
export const FORGOT_PASSWORD = `producer_password_resets`;
export const RESOURCE_POSTS = 'posts';
export const RESOURCE_MANAGERS = 'managers';
export const RESOURCE_DASHBOARDS = 'dashboard';
export const RESOURCE_BANNERS = 'banners';
export const RESOURCE_RADIOS = 'radios';
export const RESOURCE_EVENTS = 'events';
export const RESOURCE_CODE_EVENTS = 'code_events';

export const DELETE_MANAGER = (id) => `${RESOURCE_MANAGERS}/${id}`;
export const GET_BANNERS = 'banners';
export const DELETE_BANNER = (id) => `banners/${id}`;
export const DELETE_POST = (id) => `posts/${id}`;
export const DELETE_EVENT = (id) => `events/${id}`;
