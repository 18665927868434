import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {Types: ManagerTypes, Creators: ManagerActions} = createActions({
  createManager: ['payload', 'callback'],
  createManagerSuccess: ['payload'],
  listManager: ['params'],
  listManagerSuccess: ['payload'],
  deleteManager: ['payload', 'callback'],
  managerRequestSuccess: ['originalType'],
  managerRequestFailure: ['error', 'originalType'],
});

const INITIAL_STATE = {
  data: [],
  resource: null,
  error: null,
  managerRequests: {
    [ManagerTypes.LIST_MANAGER]: REQUEST_NOT_STARTED,
    [ManagerTypes.CREATE_MANAGER]: REQUEST_NOT_STARTED,
    [ManagerTypes.DELETE_MANAGER]: REQUEST_NOT_STARTED,
  },
};

const listManager = (state) => ({
  ...state,
  managerRequests: {
    ...INITIAL_STATE.managerRequests,
    [ManagerTypes.LIST_MANAGER]: REQUEST_PENDING,
  },
});

const listManagerSuccess = (state, {payload}) => ({
  ...state,
  data: payload.managers,
  managerRequests: {
    ...INITIAL_STATE.managerRequests,
    [ManagerTypes.LIST_MANAGER]: REQUEST_RESOLVED,
  },
});

const createManager = (state) => ({
  ...state,
  managerRequests: {
    ...INITIAL_STATE.managerRequests,
    [ManagerTypes.CREATE_MANAGER]: REQUEST_PENDING,
  },
});

const createManagerSuccess = (state) => ({
  ...state,
  managerRequests: {
    ...INITIAL_STATE.managerRequests,
    [ManagerTypes.CREATE_MANAGER]: REQUEST_RESOLVED,
  },
});

const deleteManager = (state) => ({
  ...state,
  managerRequests: {
    ...INITIAL_STATE.managerRequests,
    [ManagerTypes.DELETE_MANAGER]: REQUEST_PENDING,
  },
});

const managerRequestSuccess = (state, {originalType}) => ({
  ...state,
  managerRequests: {
    ...state.managerRequests,
    [originalType]: REQUEST_RESOLVED,
  },
});

const managerRequestFailure = (state, {error: {response}, originalType}) => ({
  ...state,
  error: response,
  managerRequests: {
    ...state.managerRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ManagerTypes.LIST_MANAGER]: listManager,
  [ManagerTypes.LIST_MANAGER_SUCCESS]: listManagerSuccess,
  [ManagerTypes.CREATE_MANAGER]: createManager,
  [ManagerTypes.DELETE_MANAGER]: deleteManager,
  [ManagerTypes.CREATE_MANAGER_SUCCESS]: createManagerSuccess,
  [ManagerTypes.MANAGER_REQUEST_SUCCESS]: managerRequestSuccess,
  [ManagerTypes.MANAGER_REQUEST_FAILURE]: managerRequestFailure,
});
