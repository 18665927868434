import React, {useMemo} from 'react';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import {useDispatch, useSelector} from 'react-redux';

import SideModal from 'components/core/SideModal';
import {useParams} from 'react-router-dom';

import * as yup from 'yup';
import Button from 'components/core/Button';
import * as S from './AddManager.styles';
import Input from 'components/core/form/Input';
import {useForm} from 'react-hook-form';
import {isPending} from 'lib/core/request';
import Loading from 'components/core/Loading';
import {ManagerActions} from 'store/manager/manager.ducks';

const AddManager = ({collapse, callBack, afterCreate}) => {
  const dispatch = useDispatch();

  const {managerRequests} = useSelector(({manager}) => manager);
  const {id} = useParams();
  const validationSchema = useMemo(
    () =>
      yup.object({
        email: yup.string().required('Campo e-mail é obrigatório'),
      }),
    [],
  );
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,

    setValue,
    errors,
  } = useForm({
    resolver,
    defaultValues: {
      email: '',
    },
  });

  const successCreate = () => {
    setValue('email', '');
    afterCreate();
  };

  const onSubmit = (data) => {
    const newData = {...data, event_id: id};
    dispatch(ManagerActions.createManager(newData, successCreate));
  };

  return (
    <SideModal collapse={collapse} callBack={callBack}>
      <S.Content onSubmit={handleSubmit(onSubmit)}>
        <S.Header>Adicionar gerente</S.Header>
        <Input
          inputRef={register}
          label="E-mail"
          type="text"
          name="email"
          placeholder="Informe o e-mail"
          errors={errors.email && errors.email.message}></Input>
        <Button
          type="submit"
          color="primary"
          disabled={isPending(managerRequests.CREATE_MANAGER)}>
          {isPending(managerRequests.CREATE_MANAGER) ? <Loading /> : 'Salvar'}
        </Button>
      </S.Content>
    </SideModal>
  );
};
AddManager.defaultProps = {
  collapse: false,
  callBack: () => {},
  afterCreate: () => {},
};
export default AddManager;
