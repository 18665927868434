import React, {useState, useEffect} from 'react';
import {RadioContainer, RadioInput, Label} from './Radio.styles';
const Radio = ({
  className,
  label,
  name,
  getValues,
  onChangeValue,
  checked,
  inputRef,
  ...props
}) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(checked);
  }, [checked]);
  return (
    <Label
      onChange={() => {
        setValue(!value);
        onChangeValue(!value);
      }}>
      <RadioContainer className={className}>
        <RadioInput
          name={name}
          defaultChecked={value}
          {...props}
          ref={inputRef}
        />
      </RadioContainer>
      <span>{label} </span>
    </Label>
  );
};
Radio.defaultProps = {
  onChangeValue: () => {},
};
export default Radio;
