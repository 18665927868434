import React from 'react';
import {Route, Switch, Redirect} from 'react-router';
import {ConnectedRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';

import isAuthenticated from 'lib/core/isAuthenticated';
import urls from 'constants/urls';
import Auth from 'pages/auth';

import ShowEvent from 'pages/events/show';
import Events from 'pages/events/list';
import CreatePost from 'pages/posts/create';
export const history = createBrowserHistory();
history.listen(() => {
  window.scrollTo(0, 0);
});

const PrivateRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: urls.ROUTES.LOGIN,
              state: {from: props.location},
            }}
          />
        )
      }
    />
  );
};

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path={urls.ROUTES.LOGIN} component={Auth} />
      <PrivateRoute exact path={urls.ROUTES.APP} component={Events} />

      <PrivateRoute exact path={urls.ROUTES.EVENTS} component={Events} />
      <PrivateRoute exact path={urls.ROUTES.CREATE_POST} component={CreatePost} />
      <PrivateRoute exact path={urls.ROUTES.SHOW_EVENT} component={ShowEvent} />
    </Switch>
  </ConnectedRouter>
);

PrivateRoute.propTypes = {};

PrivateRoute.defaultProps = {};

export default Routes;
