import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Container = styled.div`
  text-align: left;
  width: 100%;
  height: 60px;
  overflow: auto;
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  float: right;
  text-align: center;
  max-width: 100px;
  height: 40px;
  color: ${tokens.colors.white};
  background: ${tokens.colors.pink};
  border-radius: 20px;
  padding: 10px 12px 9px 12px;
  margin-right: ${tokens.spacing.sm};
`;
