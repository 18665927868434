import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {SIGN_IN, FORGOT_PASSWORD} from 'constants/endpoints';

import {history} from 'constants/routes';
import {POST} from 'constants/verbs';

import {SET, REMOVE} from 'lib/core/localStorage';
import urls from 'constants/urls';
import {AuthActions, AuthTypes} from './auth.ducks';

export function* signIn({payload, type}) {
  try {
    const response = yield api({
      method: POST,
      url: SIGN_IN,
      data: payload,
    });
    yield SET('access-token', {token: response.data.jwt});
    history.push(urls.ROUTES.EVENTS);
    yield put(AuthActions.authRequestSuccess(type));
  } catch (error) {
    yield put(AuthActions.authRequestFailure(error, type));
  }
}
export function* forgotPassword({payload, type}) {
  try {
    yield api({
      method: POST,
      url: FORGOT_PASSWORD,
      data: payload,
    });
    yield put(AuthActions.authRequestSuccess(type));
    history.push(urls.ROUTES.DASHBOARD);
  } catch (error) {
    yield put(AuthActions.authRequestFailure(error, type));
  }
}
export function* signOut() {
  yield REMOVE('access-token');
  history.push(urls.ROUTES.LOGIN);
}
export function* watchSagas() {
  yield takeLatest(AuthTypes.SIGN_IN, signIn);
  yield takeLatest(AuthTypes.FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(AuthTypes.SIGN_OUT, signOut);
}
