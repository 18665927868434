import React from 'react';
import * as S from './CardProfile.styles';
import Button from 'components/core/Button';

const CardProfileDataContent = ({children, onClick}) => {
  return (
    <S.CardProfileDataContent>
      {children}
      <Button type="button" color="secondary" onClick={onClick}>
        Ver mais dados
      </Button>
    </S.CardProfileDataContent>
  );
};
CardProfileDataContent.defaultProps = {
  onClick: () => {},
};
export default CardProfileDataContent;
