import 'rc-time-picker/assets/index.css';
import React from 'react';

import * as S from './InfoBottom.styles';

const InfoBottom = ({event}) => {
  return (
    <S.Container>
      <b>{event.name}</b>
      {event.last_code_event && <S.Label>{event.last_code_event}</S.Label>}
    </S.Container>
  );
};

export default InfoBottom;
