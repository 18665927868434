import React, {useState} from 'react';
import * as S from './EventDetail.styles';
import {Row} from 'components/structure/Row';
import {Col} from 'components/structure/Col';

import {
  CardProfile,
  CardProfileInfo,
  CardProfileData,
} from 'components/core/CardProfile';

import IconInstagram from 'assets/icons/icInstagram.svg';
import IconCalendar from 'assets/icons/icCalendar.svg';
import IconLight from 'assets/icons/icLight.svg';
import IconProfile from 'assets/icons/icProfile.svg';
import IconLocation from 'assets/icons/icLocation.svg';
import IconComments from 'assets/icons/icComments.svg';

import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import image from 'assets/images/image.png';
import Loading from 'components/core/Loading';

import {formatDate} from 'lib/core/utility';
import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending, isFinished} from 'lib/core/request';

import TableManagers from './Managers/TableManagers/';
import Input from 'components/core/form/Input';
import Button from 'components/core/Button';
import {ModalConfirm} from 'components/core/ModalConfirm';
import {GET} from 'lib/core/localStorage';
import jwt from 'jsonwebtoken';

const EventDetail = ({
  register,
  getValues,
  handleSubmit,
  errors,
  request,
  onSubmit,
}) => {
  const code = getValues().code ? getValues().code : '';
  const {resource, eventRequests} = useSelector(({event}) => event);
  const {token} = GET('access-token');
  const data = jwt.decode(token);
  const owner_id = data.id;

  const {id} = useParams();
  const [modalOpen, setModalOpen] = useState(null);
  const setModal = (modal) => {
    if (modal === modalOpen) {
      setModalOpen(null);
    } else {
      setModalOpen(modal);
    }
  };
  const closeModal = () => {
    setModalOpen(null);
  };

  return (
    <S.DashboardContent>
      {isPending(eventRequests.GET_EVENT) && <LoadingFullScreen />}

      {isFinished(eventRequests.GET_EVENT) && (
        <S.WrapperCard>
          <Row>
            <Col desktop="5" mobile="12">
              <CardProfile>
                <S.ImageContainer>
                  <S.ImageContent
                    src={
                      resource?.cover ? resource.cover : image
                    }></S.ImageContent>
                </S.ImageContainer>
                <CardProfileInfo color="orange">
                  {resource?.role === 'owner' ? 'Meu evento' : 'Convidado'}
                </CardProfileInfo>
                <S.SubHeader>
                  <S.Title>{resource?.name}</S.Title>
                </S.SubHeader>
              </CardProfile>
              <CardProfile>
                <S.FormCode id="code-form" onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    inputRef={register}
                    label="Codigo do evento"
                    type="text"
                    name="code"
                    placeholder="Informe o codigo"
                    errors={errors.code && errors.code.message}></Input>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => setModal('confirmSave')}
                    disabled={isPending(request)}>
                    {isPending(request) ? <Loading /> : 'Salvar'}
                  </Button>
                </S.FormCode>
              </CardProfile>
            </Col>
            <Col desktop="7" mobile="12">
              <Row>
                <Col desktop="12">
                  <CardProfile>
                    <S.BoxContent>
                      <S.Title>Informações</S.Title>
                    </S.BoxContent>
                    <S.BoxContent>
                      <CardProfileData icon={IconCalendar} name="Data">
                        {formatDate(resource?.start_at, 'dd/mm/yyyy hh:ss')} até{' '}
                        {formatDate(resource?.end_at, 'dd/mm/yyyy hh:ss')}
                      </CardProfileData>
                      <CardProfileData icon={IconLocation} name="Endereço">
                        {resource?.address
                          ? resource.address
                          : 'Não disponível'}
                      </CardProfileData>
                      <CardProfileData icon={IconComments} name="Benefícios">
                        {resource?.benefits
                          ? resource.benefits
                          : 'Não disponível'}
                      </CardProfileData>
                      <CardProfileData
                        icon={IconCalendar}
                        name="Última publicação">
                        {resource?.last_post_date
                          ? formatDate(
                              resource?.last_post_date,
                              'dd/mm/yyyy hh:ss',
                            )
                          : 'Não disponível'}
                      </CardProfileData>

                      <CardProfileData
                        icon={IconProfile}
                        name="Divulgadores ativos">
                        {resource?.active_promoters
                          ? resource.active_promoters
                          : 'Não disponível'}
                      </CardProfileData>
                      <CardProfileData
                        icon={IconComments}
                        name="Grupo no Whatsapp">
                        {resource?.whatsapp_link
                          ? resource.whatsapp_link
                          : 'Não disponível'}
                      </CardProfileData>
                      <CardProfileData
                        icon={IconComments}
                        name="Mais informações">
                        {resource?.extra ? resource.extra : 'Não disponível'}
                      </CardProfileData>
                      <CardProfileData icon={IconLight} name="Regras">
                        {resource?.rules ? resource.rules : 'Não disponível'}
                      </CardProfileData>
                      <CardProfileData icon={IconComments} name="Vagas">
                        {resource?.slots ? resource.slots : 'Não disponível'}
                      </CardProfileData>
                      <CardProfileData
                        icon={IconInstagram}
                        name="Mínimo de seguidores">
                        {resource?.min_followers
                          ? resource.min_followers
                          : 'Não disponível'}
                      </CardProfileData>

                      <CardProfileData icon={IconComments} name="Segmento">
                        {resource?.segment.name
                          ? resource.segment.name
                          : 'Não disponível'}
                      </CardProfileData>
                    </S.BoxContent>
                  </CardProfile>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col desktop="12">
              {owner_id === resource.owner_id && <TableManagers eventId={id} />}
            </Col>
          </Row>
        </S.WrapperCard>
      )}
      <ModalConfirm
        collapse={modalOpen === 'confirmSave'}
        onCancel={closeModal}
        callBack={closeModal}
        form={'code-form'}
        submitTitle={'Sim'}
        headerTitle={'Confirmar alteração.'}
        messageTitle={`O Código ${resource?.last_code_event} deixará de funcionar. Você tem certeza que deseja alterar para o código ${code}?`}
      />
    </S.DashboardContent>
  );
};

export default EventDetail;
