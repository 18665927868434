import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const InputContainer = styled.div`
  margin-top: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
  .react-datepicker__input-container {
    input {
      display: block;
      width: 100%;
      height: 50px;
      padding: 16px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      &::placeholder {
        color: ${tokens.colors.black03};
      }
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__month-text--keyboard-selected {
    background-color: ${tokens.colors.green02};
  }
  .react-datepicker__month-text.react-datepicker__month--selected:hover {
    background-color: ${tokens.colors.green01};
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container input {
      border-radius: 12px !important;
    }
  }
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${tokens.colors.pink};
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${tokens.colors.pink};
  }
`;
export const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: ${tokens.spacing.sm};
  color: ${tokens.colors.black01};
`;
export const AlignEnd = styled.div`
  text-align: end;
`;
export const Required = styled.span`
  color: red;
  font-size: 14px;
`;
