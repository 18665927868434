import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {
  RESOURCE_EVENTS,
  basePhoto,
  DELETE_EVENT,
  RESOURCE_CODE_EVENTS,
} from 'constants/endpoints';
import {GET, POST, PUT, DELETE} from 'constants/verbs';
import {EventActions, EventTypes} from './event.ducks';
import {history} from 'constants/routes';
import urls from 'constants/urls';

export function* getEvents({type}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_CODE_EVENTS}/event_list`,
    });

    yield put(EventActions.getEventsSuccess(response.data.events));
  } catch (error) {
    yield put(EventActions.eventRequestFailure(error, type));
  }
}

export function* deleteEvent({payload, type}) {
  try {
    yield api({
      method: DELETE,
      url: DELETE_EVENT(payload),
    });

    yield put(EventActions.eventRequestSuccess(type));
    yield put(EventActions.getEvents());
  } catch (error) {
    yield put(EventActions.eventRequestFailure(error, type));
  }
}
export function* getEvent({payload, type}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_CODE_EVENTS}/event_show?event_id=${payload}`,
    });

    yield put(
      EventActions.getEventSuccess({
        ...response.data.event,
        cover: response.data.event.cover.includes('https')
          ? response.data.event.cover
          : `${basePhoto}${response.data.event.cover}`,
      }),
    );
  } catch (error) {
    yield put(EventActions.eventRequestFailure(error, type));
  }
}
export function* updateEvent({id, payload, type}) {
  try {
    yield api({
      method: PUT,
      url: `${RESOURCE_EVENTS}/${id}`,
      data: payload,
    });

    yield put(EventActions.updateEventSuccess());
    yield history.push(urls.LINKS.EVENTS);
  } catch (error) {
    yield put(EventActions.eventRequestFailure(error, type));
  }
}
export function* createEvent({payload, type}) {
  try {
    yield api({
      method: POST,
      url: RESOURCE_EVENTS,
      data: payload,
    });

    yield put(EventActions.createEventSuccess());
    yield history.push(urls.LINKS.EVENTS);
  } catch (error) {
    yield put(EventActions.eventRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(EventTypes.GET_EVENTS, getEvents);
  yield takeLatest(EventTypes.GET_EVENT, getEvent);
  yield takeLatest(EventTypes.CREATE_EVENT, createEvent);
  yield takeLatest(EventTypes.UPDATE_EVENT, updateEvent);
  yield takeLatest(EventTypes.DELETE_EVENT, deleteEvent);
}
