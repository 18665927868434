import React from 'react';
import {InputTagStyle, Label} from './InputTag.styles';
import {WithContext as ReactTags} from 'react-tag-input';

const suggestions = [];
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const InputTag = ({label, tags, setTags}) => {
  const handleDelete = (i) => {
    setTags(tags.filter((_, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  return (
    <InputTagStyle>
      <Label>{label} </Label>

      <ReactTags
        tags={tags}
        placeholder="Adicione as menções contendo o @ / Link e pressione enter. Confirme se ele foi adicionado corretamente acima"
        suggestions={suggestions}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        inputFieldPosition="bottom"
        autocomplete
      />
    </InputTagStyle>
  );
};

InputTag.defaultProps = {
  onChangeValue: () => {},
  label: 'Adicionar Menções e Links',
};
export default InputTag;
