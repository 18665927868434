import React from "react";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import * as S from "./DeleteModal.styles";

export const DeleteModal = ({ itemName, collapse, callBack, onCancel }) => {
  const submit = () => {
    callBack();
  };

  return (
    <Modal collapse={collapse}>
      <S.Content>
        <S.Header>Excluir {itemName}</S.Header>

        <S.Title>Você tem certeza que deseja deletar esse {itemName}?</S.Title>
        <S.ButtonContainer>
          <Button
            type="submit"
            color="secondary"
            padding="14px 14px"
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            padding="14px 14px"
            onClick={submit}
          >
            Excluir 
          </Button>
        </S.ButtonContainer>
      </S.Content>
    </Modal>
  );
};

DeleteModal.defaultProps = {
  collapse: false,
  itemName: "",
  callBack: () => {},
  onCancel: () => {},
};
