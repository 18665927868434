import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Select = styled.select`
  border: 0px;
  background-color: transparent;
  outline: 0px;
  color: ${tokens.colors.pink};
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  font-family: ${tokens.fonts.semibold};
`;
