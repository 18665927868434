import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {Types: UserTypes, Creators: UserActions} = createActions({
  listUsers: ['params'],
  listUsersSuccess: ['payload'],
  getUser: ['params'],
  getUserSuccess: ['payload'],
  userRequestFailure: ['error'],
});

const INITIAL_STATE = {
  listUsers: [],
  totalPage: 0,
  error: null,
  userRequests: {
    [UserTypes.LIST_USERS]: REQUEST_NOT_STARTED,
  },
};

const listUsers = (state) => ({
  ...state,
  userRequests: {
    ...INITIAL_STATE.userRequests,
    [UserTypes.LIST_USERS]: REQUEST_PENDING,
  },
});
const listUsersSuccess = (state, {payload}) => ({
  ...state,
  listUsers: payload,
  userRequests: {
    ...INITIAL_STATE.userRequests,
    [UserTypes.LIST_USERS]: REQUEST_RESOLVED,
  },
});
const getUser = (state) => ({
  ...state,
  userRequests: {
    ...INITIAL_STATE.userRequests,
    [UserTypes.GET_USER]: REQUEST_PENDING,
  },
});
const getUserSuccess = (state, {payload}) => ({
  ...state,
  userData: payload,
  userRequests: {
    ...INITIAL_STATE.userRequests,
    [UserTypes.GET_USER]: REQUEST_RESOLVED,
  },
});
const userRequestFailure = (state, {error: {response}}) => ({
  ...state,
  error: response,
  userRequests: {
    ...state.userRequests,
    [UserTypes.GET_USER]: REQUEST_REJECTED,
    [UserTypes.LIST_USERS]: REQUEST_REJECTED,
    [UserTypes.ADD_TRAINER_USER]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [UserTypes.LIST_USERS]: listUsers,
  [UserTypes.LIST_USERS_SUCCESS]: listUsersSuccess,
  [UserTypes.GET_USER]: getUser,
  [UserTypes.GET_USER_SUCCESS]: getUserSuccess,
  [UserTypes.USER_REQUEST_FAILURE]: userRequestFailure,
});
