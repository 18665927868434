import styled from 'styled-components';
import {variant} from 'styled-system';
import tokens from 'assets/styles/tokens';

export const DashboardContent = styled.div``;

export const Header = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${tokens.colors.black01};
  padding: ${tokens.spacing.lg} 0px;
`;

export const SubHeader = styled.div`
  padding: 5px 12px 5px 0px;
  display: flex;
  justify-content: center;
`;
export const BoxContent = styled.div`
  padding: ${({padding}) => padding || '10px'};
`;
export const Title = styled('div')(
  {
    fontSize: '18px',
    lineHeight: '24px',
    color: tokens.colors.black01,
    fontWeight: 'bold',
  },
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.black01,
      },
    },
  }),
);

export const FormCode = styled.form``;

export const ImageContent = styled.img`
  width: 100%;
  height: 250px;
`;
export const ImageContainer = styled.div`
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 10px;
  margin-top: -16px;
`;

export const Filter = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${tokens.colors.black01};
`;
export const WrapperCard = styled.div`
  margin: -15px;
  ${tokens.media.xs} {
    margin: none;
  }
`;

export const ButtonFilter = styled.div`
  cursor: pointer;
  font-weight: bold;
  margin-left: 16px;
  font-family: ${tokens.fonts.bold};
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${({active}) =>
    active ? tokens.colors.green02 : tokens.colors.black03};
  svg {
    margin-right: 4px;
    rect {
      fill: ${({active}) =>
        active ? tokens.colors.green02 : tokens.colors.black03};
    }
  }
`;

export const EditProfile = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const AlignButton = styled.div`
  ${({active}) => active && 'text-align: center;'}
`;
export const ActionContent = styled.div`
  display: flex;
`;
