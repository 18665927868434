import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const NavTop = styled.div`
  background-color: ${tokens.colors.midnight};
  height: 73px;
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${tokens.media.xs} {
    height: auto;
    display: block;
    position: relative;
  }
`;
export const LogoContainer = styled.div`
  justify-content: center;
  display: flex;
  width: 15%;
  ${tokens.media.xs} {
    width: 100%;
    padding-top: 10px;
    text-align: center;
    display: block;
  }
`;
export const MenuContainer = styled.div`
  max-width: 1040px;
  width: 70%;
  display: flex;
  justify-content: center;
  ${tokens.media.xs} {
    width: 100%;
  }
`;
export const UserContainer = styled.div`
  width: 15%;
  color: ${tokens.colors.white};
  ${tokens.media.xs} {
    width: 100%;
    text-align: center;
  }
`;
export const Logo = styled.img`
  height: auto;
  width: 150px;
`;

export const TopNav = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;
export const Menu = styled.li`
  float: left;
  @media screen and (max-width: 600px) {
    float: none;
  }
`;
export const Badge = styled.span`
  background: #ffffff;
  color: ${tokens.colors.black01};
  border-radius: 100px;
  font-weight: 600;
  padding: 1px 8px;
  margin-left: ${tokens.spacing.xs};
  font-size: 13px;
  line-height: 16px;
`;

export const Item = styled.span`
  display: block;
  color: white;
  text-align: center;
  cursor: pointer;
  margin-left: 24px;
  margin-right: 24px;
  padding: 26px 0px;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  &.active {
    color: ${tokens.colors.pink};
    font-weight: bold;
    border-bottom: 3px solid ${tokens.colors.pink};
  }
  &:hover:not(.active) {
    border-bottom: 3px solid ${tokens.colors.pink};
    color: ${tokens.colors.pink};
  }
`;
