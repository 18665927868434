import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {RESOURCE_POSTS, basePhoto, DELETE_POST} from 'constants/endpoints';
import {GET, POST, PUT, DELETE} from 'constants/verbs';
import {PostActions, PostTypes} from './post.ducks';
import {history} from 'constants/routes';
import urls from 'constants/urls';

export function* getPosts({type}) {
  try {
    const response = yield api({
      method: GET,
      url: RESOURCE_POSTS,
    });

    yield put(PostActions.getPostsSuccess(response.data));
  } catch (error) {
    yield put(PostActions.postRequestFailure(error, type));
  }
}

export function* deletePost({payload, type}) {
  try {
    yield api({
      method: DELETE,
      url: DELETE_POST(payload),
    });

    yield put(PostActions.postRequestSuccess(type));
    yield put(PostActions.getPosts());
  } catch (error) {
    yield put(PostActions.postRequestFailure(error, type));
  }
}
export function* getPost({payload, type}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_POSTS}/${payload}`,
    });

    yield put(
      PostActions.getPostSuccess({
        ...response.data.post,
        imageUrl: response.data.post.imageUrl.includes('https')
          ? response.data.post.imageUrl
          : `${basePhoto}${response.data.post.imageUrl}`,
      }),
    );
  } catch (error) {
    yield put(PostActions.postRequestFailure(error, type));
  }
}
export function* updatePost({id, payload, type}) {
  try {
    yield api({
      method: PUT,
      url: `${RESOURCE_POSTS}/${id}`,
      data: payload,
    });

    yield put(PostActions.updatePostSuccess());
    yield history.push(urls.LINKS.DASHBOARD);
  } catch (error) {
    yield put(PostActions.postRequestFailure(error, type));
  }
}
export function* createPost({payload, type}) {
  try {
    yield api({
      method: POST,
      url: RESOURCE_POSTS,
      data: payload,
    });

    yield put(PostActions.createPostSuccess());
    yield history.push(urls.LINKS.DASHBOARD);
  } catch (error) {
    yield put(PostActions.postRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(PostTypes.GET_POSTS, getPosts);
  yield takeLatest(PostTypes.GET_POST, getPost);
  yield takeLatest(PostTypes.CREATE_POST, createPost);
  yield takeLatest(PostTypes.UPDATE_POST, updatePost);
  yield takeLatest(PostTypes.DELETE_POST, deletePost);
}
