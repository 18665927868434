import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const FormContainer = styled.form`
  width: 100%;
  height: 100vh;
  margin: auto;
`;
export const FormGroup = styled.div`
  margin-top: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;

export const FormContent = styled.div`
  padding: 15px;
  display: table;
  padding-bottom: 44px;
`;

export const FormGroupInput = styled.div`
  padding: 0 15px;
  margin-top: -12px;
  margin-bottom: -12px;
`;
export const FormGroupButton = styled.div`
  padding: 15px;
`;
export const FormRadioGroupLabel = styled.label`
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: 8px;
  color: #0b0015;
  margin-top: 24px;
`;

export const FormRadioGroup = styled.div`
  label {
    margin-bottom: 12px;
  }
`;
