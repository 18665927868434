import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from "constants/request";

export const { Types: BannerTypes, Creators: BannerActions } = createActions({
  getBanners: ["payload"],
  getBannersSuccess: ["payload"],
  deleteBanner: ["payload"],
  bannerRequestSuccess: ["originalType"],
  bannerRequestFailure: ["error", "originalType"],
  getBanner: ["payload"],
  getBannerSuccess: ["payload"],
  createBanner: ["payload"],
  createBannerSuccess: ["payload"],
  updateBanner: ["id", "payload"],
  updateBannerSuccess: ["payload"],
});

const INITIAL_STATE = {
  data: [],
  resource: null,
  error: null,
  bannerRequests: {
    [BannerTypes.GET_BANNERS]: REQUEST_NOT_STARTED,
    [BannerTypes.GET_BANNER]: REQUEST_NOT_STARTED,
    [BannerTypes.UPDATE_BANNER]: REQUEST_NOT_STARTED,
    [BannerTypes.CREATE_BANNER]: REQUEST_NOT_STARTED,
    [BannerTypes.DELETE_BANNER]: REQUEST_NOT_STARTED,
  },
};

const getBanners = (state) => ({
  ...state,
  bannerRequests: {
    ...INITIAL_STATE.bannerRequests,
    [BannerTypes.GET_BANNERS]: REQUEST_PENDING,
  },
});

const getBannersSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  bannerRequests: {
    ...INITIAL_STATE.bannerRequests,
    [BannerTypes.GET_BANNERS]: REQUEST_RESOLVED,
  },
});

const deleteBanner = (state) => ({
  ...state,
  bannerRequests: {
    ...INITIAL_STATE.bannerRequests,
    [BannerTypes.DELETE_BANNER]: REQUEST_PENDING,
  },
});

const bannerRequestSuccess = (state, { originalType }) => ({
  ...state,
  bannerRequests: {
    ...state.bannerRequests,
    [originalType]: REQUEST_RESOLVED,
  },
});

const bannerRequestFailure = (
  state,
  { error: { response }, originalType }
) => ({
  ...state,
  error: response,
  bannerRequests: {
    ...state.bannerRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const createBanner = (state) => ({
  ...state,
  bannerRequests: {
    ...INITIAL_STATE.bannerRequests,
    [BannerTypes.CREATE_BANNER]: REQUEST_PENDING,
  },
});

const createBannerSuccess = (state) => ({
  ...state,
  bannerRequests: {
    ...INITIAL_STATE.bannerRequests,
    [BannerTypes.CREATE_BANNER]: REQUEST_RESOLVED,
  },
});
const updateBanner = (state) => ({
  ...state,
  bannerRequests: {
    ...INITIAL_STATE.bannerRequests,
    [BannerTypes.UPDATE_BANNER]: REQUEST_PENDING,
  },
});

const updateBannerSuccess = (state) => ({
  ...state,
  bannerRequests: {
    ...INITIAL_STATE.bannerRequests,
    [BannerTypes.UPDATE_BANNER]: REQUEST_RESOLVED,
  },
});
const getBanner = (state) => ({
  ...state,
  resource: null,
  bannerRequests: {
    ...INITIAL_STATE.bannerRequests,
    [BannerTypes.GET_BANNER]: REQUEST_PENDING,
  },
});

const getBannerSuccess = (state, { payload }) => ({
  ...state,
  resource: payload,
  bannerRequests: {
    ...INITIAL_STATE.bannerRequests,
    [BannerTypes.GET_BANNER]: REQUEST_RESOLVED,
  },
});

export default createReducer(INITIAL_STATE, {
  [BannerTypes.GET_BANNERS]: getBanners,
  [BannerTypes.GET_BANNERS_SUCCESS]: getBannersSuccess,
  [BannerTypes.GET_BANNER]: getBanner,
  [BannerTypes.GET_BANNER_SUCCESS]: getBannerSuccess,
  [BannerTypes.CREATE_BANNER]: createBanner,
  [BannerTypes.CREATE_BANNER_SUCCESS]: createBannerSuccess,
  [BannerTypes.UPDATE_BANNER]: updateBanner,
  [BannerTypes.UPDATE_BANNER_SUCCESS]: updateBannerSuccess,
  [BannerTypes.DELETE_BANNER]: deleteBanner,
  [BannerTypes.BANNER_REQUEST_SUCCESS]: bannerRequestSuccess,
  [BannerTypes.BANNER_REQUEST_FAILURE]: bannerRequestFailure,
});
