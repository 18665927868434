import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const LoginContent = styled.div`
  display: grid;
  transition: all 0.4s;
  height: calc(100vh - 73px);
  place-items: center;

  background-image: linear-gradient(rgb(244, 3, 80), rgb(204, 0, 176));
`;

export const FormLogin = styled.form`
  display: table-cell;
  width: 510px;
  transition: all 0.4s;
  padding: 32px;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  transition: all 1s;
  opacity: 1;
  transition-delay: 0s;
  color: ${tokens.colors.black01};
`;
export const FormGroup = styled.div`
  margin-top: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;
