/* eslint-disable import/no-anonymous-default-export */
const dev = {
  // URL: 'https://api.canalplaymix.com',
  // URL: 'http://localhost:3000',
  URL: 'https://api.divulgai.app',
};

const prod = {
  URL: 'https://api.divulgai.app',
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default {
  ...config,
};
