import styled from 'styled-components';
import tokens from 'assets/styles/tokens';
import icArrow from 'assets/icons/icArrow.svg';

export const Select = styled.select`
  border: 1px solid #ccc;
  &::placeholder {
    color: ${tokens.colors.black03};
  }
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(${icArrow});
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 1rem;
  padding-right: 2rem;
  color: #555;
`;
export const InputContainer = styled.div`
  margin-top: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;
export const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: ${tokens.spacing.sm};
  color: ${tokens.colors.black01};
`;
export const AlignEnd = styled.div`
  text-align: end;
`;
export const Required = styled.span`
  color: red;
  font-size: 14px;
`;
