import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {Types: EventTypes, Creators: EventActions} = createActions({
  getEvents: ['payload'],
  getEventsSuccess: ['payload'],
  deleteEvent: ['payload'],
  eventRequestSuccess: ['originalType'],
  eventRequestFailure: ['error', 'originalType'],
  getEvent: ['payload'],
  getEventSuccess: ['payload'],
  createEvent: ['payload'],
  createEventSuccess: ['payload'],
  updateEvent: ['id', 'payload'],
  updateEventSuccess: ['payload'],
});

const INITIAL_STATE = {
  data: [],
  resource: null,
  error: null,
  eventRequests: {
    [EventTypes.GET_EVENTS]: REQUEST_NOT_STARTED,
    [EventTypes.GET_EVENT]: REQUEST_NOT_STARTED,
    [EventTypes.UPDATE_EVENT]: REQUEST_NOT_STARTED,
    [EventTypes.CREATE_EVENT]: REQUEST_NOT_STARTED,
    [EventTypes.DELETE_EVENT]: REQUEST_NOT_STARTED,
  },
};

const getEvents = (state) => ({
  ...state,
  eventRequests: {
    ...INITIAL_STATE.eventRequests,
    [EventTypes.GET_EVENTS]: REQUEST_PENDING,
  },
});

const getEventsSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  eventRequests: {
    ...INITIAL_STATE.eventRequests,
    [EventTypes.GET_EVENTS]: REQUEST_RESOLVED,
  },
});

const deleteEvent = (state) => ({
  ...state,
  eventRequests: {
    ...INITIAL_STATE.eventRequests,
    [EventTypes.DELETE_EVENT]: REQUEST_PENDING,
  },
});

const eventRequestSuccess = (state, {originalType}) => ({
  ...state,
  eventRequests: {
    ...state.eventRequests,
    [originalType]: REQUEST_RESOLVED,
  },
});

const eventRequestFailure = (state, {error: {response}, originalType}) => ({
  ...state,
  error: response,
  eventRequests: {
    ...state.eventRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const createEvent = (state) => ({
  ...state,
  eventRequests: {
    ...INITIAL_STATE.eventRequests,
    [EventTypes.CREATE_EVENT]: REQUEST_PENDING,
  },
});

const createEventSuccess = (state) => ({
  ...state,
  eventRequests: {
    ...INITIAL_STATE.eventRequests,
    [EventTypes.CREATE_EVENT]: REQUEST_RESOLVED,
  },
});
const updateEvent = (state) => ({
  ...state,
  eventRequests: {
    ...INITIAL_STATE.eventRequests,
    [EventTypes.UPDATE_EVENT]: REQUEST_PENDING,
  },
});

const updateEventSuccess = (state) => ({
  ...state,
  eventRequests: {
    ...INITIAL_STATE.eventRequests,
    [EventTypes.UPDATE_EVENT]: REQUEST_RESOLVED,
  },
});
const getEvent = (state) => ({
  ...state,
  resource: null,
  eventRequests: {
    ...INITIAL_STATE.eventRequests,
    [EventTypes.GET_EVENT]: REQUEST_PENDING,
  },
});

const getEventSuccess = (state, {payload}) => ({
  ...state,
  resource: payload,
  eventRequests: {
    ...INITIAL_STATE.eventRequests,
    [EventTypes.GET_EVENT]: REQUEST_RESOLVED,
  },
});

export default createReducer(INITIAL_STATE, {
  [EventTypes.GET_EVENTS]: getEvents,
  [EventTypes.GET_EVENTS_SUCCESS]: getEventsSuccess,
  [EventTypes.GET_EVENT]: getEvent,
  [EventTypes.GET_EVENT_SUCCESS]: getEventSuccess,
  [EventTypes.CREATE_EVENT]: createEvent,
  [EventTypes.CREATE_EVENT_SUCCESS]: createEventSuccess,
  [EventTypes.UPDATE_EVENT]: updateEvent,
  [EventTypes.UPDATE_EVENT_SUCCESS]: updateEventSuccess,
  [EventTypes.DELETE_EVENT]: deleteEvent,
  [EventTypes.EVENT_REQUEST_SUCCESS]: eventRequestSuccess,
  [EventTypes.EVENT_REQUEST_FAILURE]: eventRequestFailure,
});
