import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Content = styled.form`
  padding: ${tokens.spacing.lg};
`;
export const Header = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${tokens.colors.black01};
  padding-bottom: ${tokens.spacing.lg};
`;

export const Box = styled.div`
  background: ${tokens.colors.white};
  border: 1px solid #d6d9dc;
  box-sizing: border-box;
  border-radius: 8px;
  padding: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;

export const HeaderName = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: ${tokens.spacing.lg};
  color: ${tokens.colors.black01};
`;
export const ContainerButton = styled.div`
  padding-top: ${tokens.spacing.md};
`;
