/* eslint-disable no-template-curly-in-string */
import React, {useMemo, useEffect} from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import Header from 'components/core/Header';
import {history} from 'constants/routes';
import {useForm, Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {useParams} from 'react-router-dom';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import {EventActions} from 'store/event/event.ducks';
import {CodeEventActions} from 'store/codeEvent/codeEvent.ducks';
import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending, isFinished} from 'lib/core/request';

import EventDetail from 'components/contexts/events/EventDetails';

const ShowEvent = () => {
  const dispatch = useDispatch();
  const {id} = useParams();

  yup.setLocale({
    mixed: {
      notType: 'Campo ${path} obrigatório',
    },
  });
  const {resource, eventRequests, error} = useSelector(({event}) => event);
  const codeEventSelector = useSelector(({codeEvent}) => codeEvent);
  const {managerRequests} = useSelector(({manager}) => manager);

  const codeEventResource = codeEventSelector.resource;

  useEffect(() => {
    isFinished(managerRequests.CREATE_MANAGER);
  }, [managerRequests]);

  useEffect(() => {
    dispatch(EventActions.getEvent(id));
  }, [dispatch, id]);

  const validationSchema = useMemo(
    () =>
      yup.object({
        code: yup.string().required('Campo code é obrigatório'),
      }),
    [],
  );
  const resolver = useYupValidationResolver(validationSchema);

  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    errors,
  } = useForm({
    resolver,
    defaultValues: {
      code: '',
    },
  });

  useEffect(() => {
    if (resource) {
      setValue('code', resource.last_code_event);
    }
  }, [resource, setValue]);
  const onSubmit = (data) => {
    const newData = {...data, event_id: id};
    dispatch(CodeEventActions.createCodeEvent(newData));
  };

  return (
    <>
      <NavbarDefault active="events" />
      {isPending(eventRequests.GET_EVENT) ||
        (isPending(codeEventSelector.codeEventRequests.CREATE_CODE_EVENT) && (
          <LoadingFullScreen />
        ))}

      <Content>
        <Container>
          <Header
            type="back"
            title="Detalhes do evento"
            onClickBack={() => history.goBack()}
            showButton={false}
          />

          <EventDetail
            getValues={getValues}
            Controller={Controller}
            control={control}
            setValue={setValue}
            register={register}
            codeEvent={codeEventResource}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            error={error}
            errors={errors}
            request={eventRequests.UPDATE_EVENT}
          />
        </Container>
      </Content>
    </>
  );
};

export default ShowEvent;
