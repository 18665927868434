import {all} from 'redux-saga/effects';
import * as authSagas from './auth/auth.sagas';
import * as userSagas from './user/user.sagas';
import * as bannerSagas from './banner/banner.sagas';
import * as postSagas from './post/post.sagas';
import * as eventSagas from './event/event.sagas';
import * as dashboardSagas from './dashboard/dashboard.sagas';
import * as codeEventSagas from './codeEvent/codeEvent.sagas';
import * as managerSagas from './manager/manager.sagas';

function* Sagas() {
  yield all([
    authSagas.watchSagas(),
    userSagas.watchSagas(),
    bannerSagas.watchSagas(),
    postSagas.watchSagas(),
    eventSagas.watchSagas(),
    dashboardSagas.watchSagas(),
    codeEventSagas.watchSagas(),
    managerSagas.watchSagas(),
  ]);
}

export default Sagas;
