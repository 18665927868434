import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {EventActions} from 'store/event/event.ducks';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import Header from 'components/core/Header';
import {Grid, ItemLarge} from 'components/structure/Grid';
import CardImage from 'components/core/CardImage';
import {history} from 'constants/routes';
import urls from 'constants/urls';
import {DeleteModal} from 'components/core/DeleteModal';
import LoadingFullScreen from 'components/core/LoadingFullScreen';
import {isPending} from 'lib/core/request';
import {basePhoto} from 'constants/endpoints';
import InfoBottom from 'components/contexts/events/list/InfoBottom';
const Events = () => {
  const dispatch = useDispatch();
  const {data, eventRequests} = useSelector(({event}) => event);
  const [idDelete, setIdDelete] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);

  const deleteItem = () => {
    dispatch(EventActions.deleteEvent(idDelete));
    setModalDelete(false);
  };
  const onCancel = () => {
    setModalDelete(false);
  };
  const deleteModalAction = (id) => {
    setIdDelete(id);
    setModalDelete(!modalDelete);
  };

  useEffect(() => {
    dispatch(EventActions.getEvents());
  }, [dispatch]);

  return (
    <>
      <NavbarDefault active="events" />
      {isPending(eventRequests.GET_EVENTS) && <LoadingFullScreen />}
      <Content>
        <Container>
          <Header
            title="Meus eventos"
            subtitle=""
            showButton={false}
            buttonText="Novo evento"
            onClick={() => {
              history.push(urls.LINKS.EVENTS);
            }}
          />
          <Grid>
            {data.map((event) => (
              <ItemLarge key={event.id}>
                <CardImage
                  item={event}
                  showClick={() => {
                    history.push(urls.LINKS.SHOW_EVENT(event.id));
                  }}
                  label={event.role === 'owner' ? 'Meu evento' : 'Convidado'}
                  code={event.last_code_event}
                  positionButtons="top"
                  bottomInfo={() => <InfoBottom event={event} />}
                  image={
                    event.cover.includes('https')
                      ? event.cover
                      : `${basePhoto}${event.cover}`
                  }
                  height="200px"
                  deleteModalAction={deleteModalAction}
                  editClick={() => {
                    history.push(urls.LINKS.EDIT_EVENT(event.id));
                  }}
                />
              </ItemLarge>
            ))}
            {data.length === 0 && 'Você não possui nenhum evento.'}
          </Grid>
        </Container>
      </Content>
      <DeleteModal
        itemName="programa de vídeo"
        collapse={modalDelete}
        callBack={deleteItem}
        onCancel={onCancel}
      />
    </>
  );
};

export default Events;
