import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Field = styled.div`  
  cursor: pointer;
  input[type='file'] {
    display: none;
  }
  label {
    background-color: transparent;
    border-radius: 4px;
    box-sizing: border-box;
    justify-content: center;
    border-radius: 12px;
    border: 1px dashed ${tokens.colors.purple};
    color: ${tokens.colors.purple};
    font: theme.fonts.textRegularMedium
    cursor: pointer;
    position: relative;
    padding: 7px 6px 7px 12px;
    display: flex;
    ${({height}) => height && `height: ${height};`}
    ${({width}) => width && `min-width: ${width};`}
    align-items: center;
    span{
        margin-right: 8px;
        cursor: pointer;
        font-size: 16px;
        span {
          font-weight: bold;
          color: ${tokens.colors.purple};
        }
    }
    svg{
      position: absolute;
      right: 8px;
    }
  }
`;

export const ImagePreview = styled.div`
  ${({src}) => src && `background-image: url(${src});`}
  background-size: cover;
  border-radius: 16px;
  ${({vHeight}) => vHeight && `height: calc(${vHeight} - 70px); `}
  ${({vWidth}) => vWidth && `min-width: ${vWidth};`}
`;

export const ContainerPreview = styled.div`
  width: 100%;
  text-align: center;
  span {
    color: ${tokens.colors.black01};
  }
  div {
    font-weight: bold;
    padding-top: 16px;
    color: ${tokens.colors.purple};
  }
`;

export const AlignEnd = styled.div`
  text-align: end;
`;
export const Required = styled.span`
  color: red;
  font-size: 14px;
`;
