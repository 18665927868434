import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import auth from './auth/auth.ducks';
import user from './user/user.ducks';
import message from './message/message.ducks';
import banner from './banner/banner.ducks';
import post from './post/post.ducks';
import event from './event/event.ducks';
import dashboard from './dashboard/dashboard.ducks';
import codeEvent from './codeEvent/codeEvent.ducks';
import manager from './manager/manager.ducks';

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    user,
    message,
    banner,
    post,
    event,
    dashboard,
    codeEvent,
    manager,
  });

const Reducers = (history) => appReducer(history);

export default Reducers;
