import React from 'react';
import * as S from './CardImage.styles';
import image2 from 'assets/images/image.png';
import Button from 'components/core/Button';
import {ReactComponent as IconEdit} from 'assets/icons/icEdit.svg';
import {ReactComponent as IconDelete} from 'assets/icons/icDelete.svg';

const CardImage = ({
  item,
  image,
  height,
  editClick,
  showClick,
  deleteModalAction,
  positionButtons,
  bottomInfo,
  label,
  showButtons,
}) => {
  const bottom = () => {
    if (positionButtons === 'down') {
      return buttonDown();
    }
    return bottomInfo();
  };
  const buttonDown = () => {
    return (
      <>
        <Button
          color="tertiary"
          width="auto"
          padding="12px 12px 10px 12px"
          onClick={editClick}>
          <IconEdit />
          Editar
        </Button>
        <Button
          color="tertiary"
          width="auto"
          padding="12px 12px 10px 12px"
          onClick={() => deleteModalAction(item.id)}>
          <IconDelete />
          Excluir
        </Button>
      </>
    );
  };
  const buttonTop = () => {
    if (positionButtons === 'down') {
      return null;
    }
    return (
      <>
        <Button
          color="secondary"
          width="auto"
          padding="10px 10px 6px 11px"
          onClick={editClick}>
          <IconEdit />
        </Button>
        <Button
          color="secondary"
          width="auto"
          padding="10px 10px 6px 11px"
          onClick={() => deleteModalAction(item.id)}>
          <IconDelete />
        </Button>
      </>
    );
  };
  return (
    <S.CardContent onClick={showClick}>
      <S.CardImageContent image={image} height={height}>
        <S.CardTopActions>
          {label !== '' && <S.Label>{label}</S.Label>}
          {showButtons && buttonTop()}
        </S.CardTopActions>
      </S.CardImageContent>
      {bottom() && <S.CardActions>{bottom()}</S.CardActions>}
    </S.CardContent>
  );
};

CardImage.defaultProps = {
  item: {image2},
  image: image2,
  height: '450px',
  editClick: () => {},
  showClick: () => {},
  deleteModalAction: () => null,
  positionButtons: 'down',
  bottomInfo: () => {},
  label: '',
  showButtons: false,
};

export default CardImage;
