/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import * as S from './Input.styles';

const TextArea = (props) => {
  const {label, inputRef, errors} = props;
  return (
    <S.InputContainer>
      <S.Label>{label} </S.Label>
      <S.InputContent ref={inputRef} {...props} resize={false} />
      {errors && (
        <S.AlignEnd>
          <S.Required>{errors}</S.Required>
        </S.AlignEnd>
      )}
    </S.InputContainer>
  );
};

export default TextArea;
