import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const InputTagStyle = styled.div`
  .ReactTags__tags {
    position: relative;
  }
  .ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
    padding: 0 4px;
  }

  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: move;
    border-radius: 2px;
  }
  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }

  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }

  .ReactTags__tagInputField {
    display: block;
    width: 100% !important;
    height: 50px !important;
    padding: 16px !important;
    font-size: 14px !important;
    line-height: 1.42857143;
    color: #555;
    background-color: transparent;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 12px;
    &::placeholder {
      color: ${tokens.colors.black03};
    }
  }
  .ReactTags__tagInput {
    width: 100% !important;
    padding-bottom: 15px;
  }

  .ReactTags__selected span {
    background: transparent !important;
    font-size: 14px !important;
    display: inline-block;
    border: 1px solid ${tokens.colors.pink} !important;
    font-weight: bold !important;
    cursor: move;
    border-radius: 12px !important;
    padding: 12px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .ReactTags__remove {
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
    text-decoration: none !important;
    outline: none;
    border: none;
    border-radius: 6px;
    color: white;
    background-color: ${tokens.colors.red01};
  }
  .tag-wrapper.ReactTags__tag {
    padding: 10px;
  }
`;

export const Label = styled.label`
  padding-top: 15px;
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: ${tokens.spacing.sm};
  color: ${tokens.colors.black01};
`;
