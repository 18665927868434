/* eslint-disable no-template-curly-in-string */
import React, {useMemo, useEffect, useState} from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import Header from 'components/core/Header';
import {history} from 'constants/routes';
import Form from 'components/contexts/posts/form';
import {useForm, Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import {PostActions} from 'store/post/post.ducks';
import {EventActions} from 'store/event/event.ducks';
import {eventToOptions} from 'lib/core/utility';
import moment from 'moment';
import 'moment/locale/pt-br';

const CreatePost = () => {
  const dispatch = useDispatch();
  // const [croppedImage, setCroppedImage] = useState('');
  const [tags, setTags] = useState([]);

  yup.setLocale({
    mixed: {
      notType: 'Campo ${path} obrigatório',
    },
  });

  const {postRequests, error} = useSelector(({post}) => post);
  const {data} = useSelector(({event}) => event);

  const validationSchema = useMemo(
    () =>
      yup.object({
        event_id: yup.string().required('Campo evento é obrigatório'),
        // name: yup.string().required('Campo nome é obrigatório'),
        scheduleOptions: yup.string().required('Campo nome é obrigatório'),
        kind: yup.string().required('Campo nome é obrigatório'),
        //imageUrl: yup.mixed().required('File is required'),
        //url: yup.string().required('Campo url obrigatório'),
      }),
    [],
  );
  const resolver = useYupValidationResolver(validationSchema);

  const {register, handleSubmit, setValue, getValues, errors, control, watch} =
    useForm({
      resolver,
    });

  const onSubmit = (form) => {
    const formData = new FormData();
    const formatedPublishedAt = form.published_at ? form.published_at : null;
    let formatedExpiredAt = null;
    const expired_at = form.expired_after
      ? moment().add(form.expired_after, 'hours')
      : moment().add(moment.duration('08:00:00'));
    formatedExpiredAt = expired_at;

    console.log('form.expired_at', form.expired_after);

    console.log('format', formatedExpiredAt);
    if (formatedPublishedAt) {
      const newDate = moment(formatedPublishedAt);
      newDate.add(form.expired_after, 'hours');
      formatedExpiredAt = moment(formatedPublishedAt).add(
        form.expired_after,
        'hours',
      );
    }
    if (form.content_kind === 'image') {
      // formData.append('image', croppedImage);
      formData.append('image', form.image[0]);
    }
    if (form.content_kind === 'video') {
      formData.append('video', form.video[0]);
    }
    if (form.kind !== 'stories' && form.kind !== 'feed') {
      formData.append('content_kind', 'special');
    } else {
      formData.append('content_kind', form.content_kind);
    }
    formData.append('event_id', form.event_id);
    formData.append('expired_at', formatedExpiredAt);
    formData.append('kind', form.kind);
    formData.append('published_at', formatedPublishedAt);
    formData.append('link', form.link);
    formData.append('hiperlink', form.hiperlink);
    if (form.description) {
      formData.append('description', form.description);
    }
    formData.append('affiliate_link', form.affiliate_link);
    tags.forEach((tag, index) => {
      formData.append(`post_tags_attributes[${index}][mention]`, tag.text);
    });
    dispatch(PostActions.createPost(formData));
  };

  useEffect(() => {
    dispatch(EventActions.getEvents());
  }, [dispatch]);

  useEffect(() => {
    setValue('scheduleOptions', 'publish');
    setValue('expired_after', 8);
  }, [setValue]);

  return (
    <>
      <NavbarDefault active="posts" />
      <Content>
        <Container>
          <Header
            type="back"
            showButton={false}
            buttonText="Publicar"
            title="Nova publicação"
            onClickBack={() => history.goBack()}
          />
          <Form
            control={control}
            Controller={Controller}
            register={register}
            getValues={getValues}
            watch={watch}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            error={error}
            errors={errors}
            request={postRequests.CREATE_POST}
            eventsOptions={eventToOptions(data)}
            // setCroppedImage={setCroppedImage}
            tags={tags}
            setTags={setTags}
          />
        </Container>
      </Content>
    </>
  );
};

export default CreatePost;
