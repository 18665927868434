import React, {useCallback, useState} from 'react';
import Table from 'components/core/Table';
import * as S from './TableManagers.styles';
import {useDispatch, useSelector} from 'react-redux';
import {BsTrash} from 'react-icons/bs';

import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending} from 'lib/core/request';
// import {history} from 'constants/routes';
// import urls from 'constants/urls';
// import {UserActions} from 'store/user/user.ducks';
import Button from 'components/core/Button';
import AddManager from '../AddManager';
import {ModalConfirm} from 'components/core/ModalConfirm';
import {ManagerActions} from 'store/manager/manager.ducks';

const TableManagers = ({eventId}) => {
  const [modalOpen, setModalOpen] = useState(null);
  const [delManager, setDelManager] = useState(null);

  const setModal = useCallback(
    (modal) => {
      if (modal === modalOpen) {
        setModalOpen(null);
      } else {
        setModalOpen(modal);
      }
    },
    [setModalOpen, modalOpen],
  );
  const closeModal = () => {
    setModalOpen(null);
  };

  const callbackCreate = () => {
    closeModal();
    fetchData();
  };

  const dispatch = useDispatch();
  const {data, managerRequests} = useSelector(({manager}) => manager);

  // const onChangeSearch = (e) => {
  //   dispatch(
  //     UserActions.listUsers({search: e.target.value, perPage: 10, page: 1}),
  //   );
  // };

  const fetchData = useCallback(() => {
    dispatch(ManagerActions.listManager({event_id: eventId}));
  }, [dispatch, eventId]);

  const confirmDelete = () => {
    dispatch(ManagerActions.deleteManager(delManager.id, fetchData));

    closeModal();
  };

  // const onClickRow = (row) => {
  //   const {id} = row;
  //   history.push(urls.LINKS.RUNNER_DETAILS(id));
  // };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'producer_name',
        minWidth: 350,
      },
      {
        Header: 'E-mail',
        accessor: 'producer_email',
        minWidth: 250,
      },
      {
        Header: 'Código',
        accessor: 'code',
        minWidth: 300,
        Cell: (props) => {
          return (
            <span>
              {props.value && props.value !== 'undefined' ? props.value : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Ações',
        accessor: 'action',
        minWidth: 50,
        Cell: (props) => {
          const item = props.cell.row.original;
          return (
            <BsTrash
              style={{width: '24px', height: '24px', cursor: 'pointer'}}
              onClick={() => {
                setModal('confirmDelete');
                setDelManager(item);
              }}
            />
          );
        },
      },
    ],
    [setModal],
  );

  return (
    <S.TableContent>
      {isPending(managerRequests.LIST_MANAGER) && <LoadingFullScreen />}
      <S.Header>
        <S.Title>Todos os Gerentes</S.Title>
        <S.FilterContainer>
          <Button
            color="primary"
            padding="8px 16px"
            onClick={() => setModal('addManager')}>
            Adicionar gerente
          </Button>
        </S.FilterContainer>
      </S.Header>
      <Table
        columns={columns}
        data={data}
        pageCount={10}
        fetchData={fetchData}
      />
      <AddManager
        collapse={modalOpen === 'addManager'}
        callBack={closeModal}
        afterCreate={callbackCreate}
      />
      <ModalConfirm
        collapse={modalOpen === 'confirmDelete'}
        onCancel={closeModal}
        callBack={confirmDelete}
        submitTitle={'Sim'}
        headerTitle={'Deletar Gerente.'}
        messageTitle={`Você tem certeza que deseja deletar o gerente ${delManager?.producer_name}?`}
      />
    </S.TableContent>
  );
};

export default TableManagers;
