/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import * as S from './SelectInput.styles';

const SelectInput = (props) => {
  const {label, inputRef, errors, options} = props;
  return (
    <S.InputContainer>
      <S.Label>{label} </S.Label>
      <S.Select ref={inputRef} {...props}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </S.Select>
      {errors && (
        <S.AlignEnd>
          <S.Required>{errors}</S.Required>
        </S.AlignEnd>
      )}
    </S.InputContainer>
  );
};

export default SelectInput;
