import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const CardImageContent = styled.div`
  background-image: url(${({image}) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  cursor: pointer;
  height: ${({height}) => height};
  border-radius: 16px 16px 0px 0px;
`;

export const CardContent = styled.div`
  width: 100%;
  height: auto;
  border-radius: 16px 16px 0px 0px;
`;

export const CardActions = styled.div`
  padding: ${tokens.spacing.md};
  background: #ffffff;
  border: 1px solid #e1e2e8;
  box-sizing: border-box;
  border-radius: 0px 0px ${tokens.spacing.md} ${tokens.spacing.md};
  display: flex;
  button {
    margin-right: ${tokens.spacing.md};
  }
`;

export const CardTopActions = styled.div`
  display: flex;
  justify-content: end;
  padding: 12px 0px;
  button {
    margin-right: ${tokens.spacing.sm};
  }
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #f2682a;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(6, 26, 44, 0.16);
  border-radius: 8px;
  padding: 10px 12px 9px 12px;
  margin-right: ${tokens.spacing.sm};
`;
